import auth from "./AuthAPI.js";
import Http from "./Http.js";
import * as APPCONFIG from "../config/app-config.js";
//import api from "./BaseAPI.js";
//import Axios from "axios";

var CACHE_FRD_PROFILE = {};
var CACHE_FRD_LIST = {};

class FriendsFinder {
  getAllUser() {
    return auth({
      method: "get",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getalluserdetails`,
    });
  }
  getAllFriends() {
    const config = {
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getChatFriendsList`,
      data: JSON.stringify({}),
    };
    console.log("CHAT Friends Response : ->", config);
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getChatFriendsList`,
      data: JSON.stringify({}),
    });
  }

  //method to send friend request
  friendRequest(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      // url: `${APPCONFIG.API_URL}api/requestedfriends`,
      url: `${APPCONFIG.API_URL}api/v1/friends/request/${Http.WORK_SPACE()}`,
      data: JSON.stringify(formValues),
    });
  }

  getUserRequestList(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getUserRequestList`,
      data: JSON.stringify(formValues),
    });
  }

  //method to find request friends
  requestedFriends(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getRequestedFriendsList`,
      data: JSON.stringify(formValues),
    });
  }
  //method to accept friends
  friendAccept(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/acceptedfriends`,
      data: JSON.stringify(formValues),
    });
  }
  friendAcceptV2(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/acceptedfriendsV2`,
      // url: `${APPCONFIG.API_URL}api/ v1/friends/accept/${Http.WORK_SPACE()}`,
      data: JSON.stringify(formValues),
    });
  }
  //method to reject friends

  friendReject(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/rejectedfriends/${Http.WORK_SPACE()}`,
      data: JSON.stringify(formValues),
    });
  }

  getRecommendedFriends(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/recomentedfriends`,
      data: JSON.stringify(formValues),
    });
  }

  getRecommendedFriendsReviews(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getReviews`,
      data: JSON.stringify(formValues),
    });
  }

  unfriendHit(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/unfriend`,
      data: JSON.stringify(formValues),
    });
  }

  onRecentSearchList(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/searchUser`,
      data: JSON.stringify(formValues),
    });
  }
  getFriendsbirthday(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getFriendsbirthday`,
      data: JSON.stringify(formValues),
    });
  }

  recommendedfriendIgnore(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/ignoreRecommendedFriend`,
      data: JSON.stringify(formValues),
    });
  }

  /**** FRD LIST CACHE & API  */

  setFriendsListCache(formValues, response) {
    console.log("Set Cache from Server FRD LIST:" + formValues.userId);
    CACHE_FRD_LIST[formValues.userId] = response;
  }

  getFriendsListCache(formValues) {
    if (CACHE_FRD_LIST[formValues.userId]) {
      console.log("Loading From Cache Friends List :" + formValues.userId);
      return CACHE_FRD_LIST[formValues.userId];
    } else {
      return null;
    }
  }

  getFriendsList(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/friendslistaccepted`,
      data: JSON.stringify(formValues),
    });
  }

  friendslistByUserId(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/friendslistByUserId`,
      data: JSON.stringify(formValues),
    });
  }

  /**** FRD LIST CACHE & API  */

  setFriendsDetailsCache(formValues, response) {
    console.log("Loading From Server Set Cache :" + formValues.userId);
    console.log(response);
    CACHE_FRD_PROFILE[formValues.userId] = response;
  }

  /**** CACHE & API  */
  getFriendsDetailsCache(formValues) {
    if (CACHE_FRD_PROFILE[formValues.userId]) {
      console.log("Loading From Cache :" + formValues.userId);
      return CACHE_FRD_PROFILE[formValues.userId];
    } else {
      return null;
    }
  }

  getFriendsDetailsbyUserid(formValues) {
    console.log("Loading From Server :" + formValues);
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getFriendsDetailsbyUserid`,
      data: JSON.stringify(formValues),
    });
  }
  getProfileDetails(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${
        APPCONFIG.API_URL
      }api/v1/user/profileDetails/${Http.WORK_SPACE()}`,
      data: JSON.stringify(formValues),
    });
  }
  /**** CACHE & API  */

  getSearchAPI(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/searchApi`,
      data: JSON.stringify(formValues),
    });
  }

  recentlyAddedFriends(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getrecentlyAddedFriends`,
      data: JSON.stringify(formValues),
    });
  }

  getUserSchoolAndClass(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getUserSchoolAndClass`,
      data: JSON.stringify(formValues),
    });
  }
  getUserBLockList(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/getUserBLockList`,
      data: JSON.stringify(formValues),
    });
  }
  unBlockAUser(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/unBlockAUser`,
      data: JSON.stringify(formValues),
    });
  }
  getUserWorkSpaceList(formValues) {
    return auth({
      method: "post",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/work_space_list`,
      data: JSON.stringify(formValues),
    });
  }
  admin_category_list() {
    return auth({
      method: "get",
      headers: Http.AUTH_HEADERS(),
      url: `${APPCONFIG.API_URL}api/admin_category_list`,
    });
  }
  //   getMutualFriendsCounts(formValues) {
  //     return auth({
  //       method: "post",
  //       headers: Http.AUTH_HEADERS(),
  //       url: `${APPCONFIG.API_URL}api/getMutualFriendsCount`,
  //       data: JSON.stringify(formValues),
  //     });
  //   }

  //   getFriendsListByPage(formValues) {
  //     return auth({
  //       method: "post",
  //       headers: Http.AUTH_HEADERS(),
  //       url: `${APPCONFIG.API_URL}api/getFriendsListWithPage`,
  //       data: JSON.stringify(formValues),
  //     });
  //   }
  //   getRecomentedFriendsSize(formValues) {
  //     return auth({
  //       method: "post",
  //       headers: Http.AUTH_HEADERS(),
  //       url: `${APPCONFIG.API_URL}api/getRecomentedFriendsSize`,
  //       data: JSON.stringify(formValues),
  //     });
  //   }
  //   filterRecomentedFriends(formValues) {
  //     return auth({
  //       method: "post",
  //       headers: Http.AUTH_HEADERS(),
  //       url: `${APPCONFIG.API_URL}api/filterRecomentedFriends`,
  //       data: JSON.stringify(formValues),
  //     });
  //   }
  //   filterGetRequestedFriendsList(formValues) {
  //     return auth({
  //       method: "post",
  //       headers: Http.AUTH_HEADERS(),
  //       url: `${APPCONFIG.API_URL}api/filterGetRequestedFriendsList`,
  //       data: JSON.stringify(formValues),
  //     });
  //   }
  //   filterFriendsList(formValues) {
  //     return auth({
  //       method: "post",
  //       headers: Http.AUTH_HEADERS(),
  //       url: `${APPCONFIG.API_URL}api/filterFriendsList`,
  //       data: JSON.stringify(formValues),
  //     });
  //   }
  //   getFriendsProfileById(formValues) {
  //     return auth({
  //       method: "post",
  //       headers: Http.AUTH_HEADERS(),
  //       url: `${APPCONFIG.API_URL}api/getFriendsProfileById`,
  //       data: JSON.stringify(formValues),
  //     });
  //   }

  //   getFriendReviews(formValues) {
  //     return auth({
  //       method: "post",
  //       headers: Http.AUTH_HEADERS(),
  //       url: `${APPCONFIG.API_URL}api/getReviewsByuser`,
  //       data: JSON.stringify(formValues),
  //     });
  //   }
  //   reviewsUpdate(formValues) {
  //     return auth({
  //       method: "post",
  //       headers: Http.AUTH_HEADERS(),
  //       url: `${APPCONFIG.API_URL}api/reviewsUpdate`,
  //       data: JSON.stringify(formValues),
  //     });
  //   }

  //   saveCompetencyLike(formValues) {
  //     return auth({
  //       method: "post",
  //       headers: Http.AUTH_HEADERS(),
  //       url: `${APPCONFIG.API_URL}api/saveCompetencyLike`,
  //       data: JSON.stringify(formValues),
  //     });
  //   }

  //   unfollowFriend(formValues) {
  //     return auth({
  //       method: "post",
  //       headers: Http.AUTH_HEADERS(),
  //       url: `${APPCONFIG.API_URL}api/followFriends`,
  //       data: JSON.stringify(formValues),
  //     });
  //   }
  //   favouriteFriend(formValues) {
  //     return auth({
  //       method: "post",
  //       headers: Http.AUTH_HEADERS(),
  //       url: `${APPCONFIG.API_URL}api/favouriteFriends`,
  //       data: JSON.stringify(formValues),
  //     });
  //   }

  //method to get static friends list
  //  friendsList(){

  //       var stringArray = Axios.post("https://bml.betalearnings.com/bca/api/getuserbyname")
  //       // [ {
  //       //   id: 1,
  //       //   username: "Jason LUW",
  //       //   description: "#Français #Luxembourgeois #BMX #Mathématiques #Biologie",
  //       //   country: "india",
  //       //   city: "chennai",
  //       //   school: "cresent school",
  //       //   class: "6A",
  //       //   language: "Tamil",
  //       //   profileImg: `${profilePhoto}`,
  //       //   coverImg: `${coverPhoto}`,
  //       //   avaliabalities: "Today",
  //       //   relationship: "Single",
  //       //   timezone: "UTC +6:30",
  //       //   yournetwork: 0,
  //       // },
  //       // {
  //       //   id: 2,
  //       //   username: "Johann JINEB",
  //       //   description: "#Français #Luxembourgeois #BMX #Mathématiques #Biologie",
  //       //   country: "America",
  //       //   city: "los angela",
  //       //   school: "cresent school",
  //       //   class: "5A",
  //       //   language: "English",
  //       //   profileImg: `${profilePhoto}`,
  //       //   coverImg: `${coverPhoto}`,
  //       //   avaliabalities: "Tomorrow",
  //       //   relationship: "Married",
  //       //   timezone: "UTC -5",
  //       //   yournetwork: 0,
  //       // },
  //       // {
  //       //   id: 3,
  //       //   username: "Sara TIANA",
  //       //   description: "#Français #Luxembourgeois #BMX #Mathématiques #Biologie",
  //       //   country: "England",
  //       //   city: "London",
  //       //   school: "Chettinad school",
  //       //   class: "9A",
  //       //   language: "English",
  //       //   profileImg: `${profilePhoto}`,
  //       //   coverImg: `${coverPhoto}`,
  //       //   avaliabalities: "Tomorrow",
  //       //   relationship: "Single",
  //       //   timezone: "UTC -5",
  //       //   yournetwork: 0,
  //       // },
  //       // {
  //       //   id: 4,
  //       //   username: "Lola COUNIX",
  //       //   description: "#Français #Luxembourgeois #BMX #Mathématiques #Biologie",
  //       //   country: "Pakistan",
  //       //   city: "Karachi",
  //       //   school: "Alhilal school",
  //       //   class: "7A",
  //       //   language: "urdu",
  //       //   profileImg: `${profilePhoto}`,
  //       //   coverImg: `${coverPhoto}`,
  //       //   avaliabalities: "Tomorrow",
  //       //   relationship: "Single",
  //       //   timezone: "UTC -5",
  //       //   yournetwork: 0,
  //       // },
  //       // {
  //       //   id: 5,
  //       //   username: "Lola Thomas PERCE",
  //       //   description: "#Français #Luxembourgeois #BMX #Mathématiques #Biologie",
  //       //   country: "Japan",
  //       //   city: "Tokyo",
  //       //   school: "IMMS school",
  //       //   class: "10A",
  //       //   language: "Japanese",
  //       //   profileImg: `${profilePhoto}`,
  //       //   coverImg: `${coverPhoto}`,
  //       //   avaliabalities: "Tomorrow",
  //       //   relationship: "Married",
  //       //   timezone: "UTC -5",
  //       //   yournetwork: 1,
  //       // },
  //       // {
  //       //   id: 6,
  //       //   username: "Guillaume ZOUAR",
  //       //   description: "#Français #Luxembourgeois #BMX #Mathématiques #Biologie",
  //       //   country: "Srilanka",
  //       //   city: "Colombo",
  //       //   school: "cresent school",
  //       //   class: "5A",
  //       //   language: "Sinhala",
  //       //   profileImg: `${profilePhoto}`,
  //       //   coverImg: `${coverPhoto}`,
  //       //   avaliabalities: "Tomorrow",
  //       //   relationship: "Single",
  //       //   timezone: "UTC -5",
  //       //   yournetwork: 1,
  //       // },
  //       // {
  //       //   id: 7,
  //       //   username: "Nicolas CHALTE",
  //       //   description: "#Français #Luxembourgeois #BMX #Mathématiques #Biologie",
  //       //   country: "malaysia",
  //       //   city: "Kuala Lumpur",
  //       //   school: "cresent school",
  //       //   class: "5A",
  //       //   language: "Sinhala",
  //       //   profileImg: `${profilePhoto}`,
  //       //   coverImg: `${coverPhoto}`,
  //       //   avaliabalities: "Tomorrow",
  //       //   relationship: "Married",
  //       //   timezone: "UTC -5",
  //       //   yournetwork: 1,
  //       // },
  //       // {
  //       //   id: 8,
  //       //   username: "Florianne SOYEZ",
  //       //   description: "#Français #Luxembourgeois #BMX #Mathématiques #Biologie",
  //       //   country: "Russia",
  //       //   city: "Moscow",
  //       //   school: "cresent school",
  //       //   class: "5A",
  //       //   language: "Russian",
  //       //   profileImg: `${profilePhoto}`,
  //       //   coverImg: `${coverPhoto}`,
  //       //   yournetwork: 1,
  //       // },]

  //     return stringArray

  //   }

  //   getSearchedRecommendedList(formValues) {
  //     return auth({
  //       method: "post",
  //       headers: Http.AUTH_HEADERS(),
  //       url: `${APPCONFIG.API_URL}api/filterRecomentedFriends`,
  //       data: JSON.stringify(formValues),
  //     });
  //   }

  //   getSearchedRequestedList(formValues) {
  //     return auth({
  //       method: "post",
  //       headers: Http.AUTH_HEADERS(),
  //       url: `${APPCONFIG.API_URL}api/filterGetRequestedFriendsList`,
  //       data: JSON.stringify(formValues),
  //     });
  //   }
}
export default new FriendsFinder();
