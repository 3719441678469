import { Paper } from "@material-ui/core";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { API_URL } from "../../config/app-config";
import { AiOutlineSend } from "react-icons/ai";
import { FiRefreshCw } from "react-icons/fi";
import { FiEdit2 } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import { MdOutlineReport } from "react-icons/md";
import { AiFillLike } from "react-icons/ai";
import { FaComment } from "react-icons/fa";
import Share from "@material-ui/icons/Share";
import * as APPCONFIG from "../../config/app-config.js";
import dummyimage from "../../img/dummygif.gif";
import { htmlValidation } from "../../utils";
import { needRenderComponent } from "../../redux/actions/commonApiActions";

const articleContentdummy = `
<p style="text-align:justify;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec lacus lacinia, malesuada orci ut, scelerisque mi. Praesent ultricies sit amet erat et condimentum. Ut in ornare elit, vel malesuada tortor. Aliquam vitae convallis libero. Integer id tristique tellus. Curabitur sit amet scelerisque massa, sed commodo purus. Integer feugiat lectus a magna vestibulum, sollicitudin vehicula odio tempor. Aliquam quam augue, volutpat sed auctor at, maximus id odio.</p>
<p style="text-align:justify;">Phasellus tellus lacus, blandit ac magna at, ultricies vestibulum purus. Suspendisse ullamcorper mauris vel blandit lobortis. Nullam lorem purus, rhoncus a nulla vel, consequat ultrices nisi. In ullamcorper tortor quis risus venenatis tempor. Pellentesque vehicula efficitur sapien, vel elementum augue ultrices eget. Duis viverra tempor urna, id pretium odio efficitur eget. Vestibulum malesuada libero eget dapibus rhoncus. Fusce facilisis gravida malesuada. In tempus, sem eu pellentesque condimentum, diam ligula vulputate eros, sit amet semper eros elit ut arcu. Duis ut purus sed nulla condimentum sodales at at massa.</p>
<p style="text-align:justify;">Duis lobortis, turpis vitae vestibulum vulputate, magna elit congue purus, ac ultrices erat ipsum sed eros. Aenean viverra tellus id consectetur vestibulum. Donec ac tristique elit. Aliquam ac placerat nulla. Nunc commodo venenatis augue, vitae efficitur felis cursus varius. Nam posuere magna at metus bibendum lobortis. Proin laoreet faucibus rutrum. Praesent nunc erat, dignissim sit amet enim eget, dapibus tincidunt magna. Aenean vestibulum urna ac tortor dignissim, placerat consequat mauris placerat. Donec eu nunc at velit rutrum egestas sit amet a mi. Cras a turpis vulputate, condimentum velit in, ornare massa. Ut efficitur lacus id nibh aliquet, nec viverra velit sollicitudin. Donec id neque purus. Sed sollicitudin velit at elit viverra, a finibus orci fermentum.</p>
<p></p>
<p></p>
<img src="https://images.pexels.com/photos/9968493/pexels-photo-9968493.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="undefined" style="float:none;height: 100%;width: 100%"/>
<p></p>
<p style="text-align:justify;">Proin tristique augue odio, at mattis velit semper nec. Ut malesuada maximus justo, in venenatis dui accumsan vel. Maecenas porttitor id urna sit amet auctor. Donec urna eros, bibendum eu convallis sit amet, finibus vitae arcu. Sed ut pulvinar velit. Integer viverra urna elit, et suscipit turpis porta a. Nulla facilisi. Pellentesque sem leo, mollis a erat vitae, convallis posuere magna. Nullam tempus imperdiet neque at iaculis.</p>
<p style="text-align:justify;">Donec rhoncus vehicula tellus at consequat. Nunc a metus tempus justo porttitor convallis vel ut ante. In hac habitasse platea dictumst. Sed euismod odio non tortor interdum, non pharetra ex facilisis. Nulla et diam sollicitudin, venenatis sapien sit amet, tempor nisi. Maecenas laoreet quis enim vel rhoncus. Pellentesque bibendum turpis a malesuada con</p>
`;
function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Article({
  authenticated = false,
  accessToken = "",
  refreshToken = "",
  ...props
}) {
  const location = useLocation();
  const query = useQuery();
  const [articles, setArticles] = React.useState(null);
  const [loader, setLoader] = useState(true);
  const [slug, setSlug] = React.useState("");
  const [articleContent, setArticleContent] =
    React.useState(articleContentdummy);
  const dispatch = useDispatch();
  const articleSlug = location.pathname;

  console.log(
    "Article Slug",
    articleSlug.slice(articleSlug.lastIndexOf("/") + 1)
  );

  React.useEffect(() => {
    const filterSlug =
      articleSlug.slice(articleSlug.lastIndexOf("/") + 1) ?? "";
    setSlug(filterSlug);
    if (!authenticated && !accessToken) {
      console.log("articleSlug", articleSlug);
      localStorage.setItem("ARTICLE_SLUG", filterSlug);
      dispatch(needRenderComponent(true));
    } else {
      localStorage.removeItem("ARTICLE_SLUG");
    }
    const getarticleContent = async () => {
      if (slug !== "") {
        const response = await axios.get(`${API_URL}auth/article/${slug}`);
        console.log("Article Content", response.data.data.article);
        setArticles(response.data.data.article ?? null);
        setLoader(false);
      }
    };
    getarticleContent();
  }, [location.pathname, slug]);

  return (
    <div
      // className={
      //   authenticated && accessToken !== "" && refreshToken !== ""
      //     ? "screenInnerGap"
      //     : "screenInnerGap artical-wrap"
      // }
      style={{ wordWrap: "break-word" }}
      // style={{
      //   display: "flex",
      //   flexDirection: "column",
      //   gap: "3rem",
      //   marginTop: "90px",
      //   marginLeft:
      //     authenticated && accessToken !== "" && refreshToken !== ""
      //       ? "5rem"
      //       : "-16%",
      // }}
    >
      <Paper>
        {loader ? (
          <div className="d-flex justify-content-center p-5">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <div>
            {articles ? (
              <>
                <div
                  style={{
                    padding: "1rem 2rem",
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.5rem",
                  }}
                >
                  <h3
                    style={{
                      fontWeight: "Bold",
                      color: "#dc3545",
                      // textAlign:"center",
                      fontSize: "xxx-large",
                    }}
                  >
                    {articles.article.title}
                  </h3>
                  <div>
                    <span style={{ fontSize: "x-large" }}>
                      {articles.userName}
                    </span>
                    <br />
                    <span style={{ fontSize: "small" }}>
                      {moment(articles.createdDate)
                        .locale("en")
                        .format("MMMM Do YYYY, h:mm")}
                    </span>
                  </div>
                </div>
                <hr />

                <div
                  style={{
                    padding: "0rem 2rem",
                  }}
                >
                  {htmlValidation(articles?.article?.description)}
                  {/* <p
                    dangerouslySetInnerHTML={{
                      __html: articles?.article?.description,
                    }}
                  ></p> */}
                  <hr />
                </div>
                <div
                  style={{
                    textAlign: "center",
                    padding: "1rem 2rem",
                  }}
                >
                  {articles?.type &&
                    articles?.type === "image" &&
                    articles?.postMediaFiles[0]?.fileName && (
                      <>
                        <img
                          alt="artical"
                          src={`${APPCONFIG.API_URL}auth/image/${articles?.postMediaFiles[0]?.fileName}`}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = dummyimage;
                          }}
                          // src={`https://bml.betalearnings.com/bca/auth/image/0f75c442-3009-44b7-ac8f-147ea224872b`}
                        />
                      </>
                    )}
                </div>
              </>
            ) : (
              <div style={{ padding: "10px" }}>
                <p>There is some problem in getting content</p>
                <p>Please contact an administartor</p>
              </div>
            )}
          </div>
        )}
      </Paper>
    </div>
  );
}

const mapStateToProps = function (state) {
  return {
    userDetails: state.loginDetailsApi,
    friendsList: state.commonApi.chatFriendslist,
    navigationType: state.commonApi.navigationType,
    selectedUser: state.chatList.selectedUser,
    authenticated: state.loginDetailsApi.keycloakAuthenticated,
    refreshToken: state.loginDetailsApi.refreshToken,
    accessToken: state.loginDetailsApi.accessToken,
  };
};

export default connect(mapStateToProps, null)(Article);
